import React from 'react'
import CallToActionBlock from 'components/CallToActionBlock'
import BlogHeader from 'components/BlogHeader'
import Layout from 'components/Layout'
import CareersOverview from '../components/CareersOverview'

const Careers = () => (
  <Layout>
    <BlogHeader />
    <CareersOverview />
    <CallToActionBlock
      title="See What We've Been Working On"
      linkText='VIEW WORK'
      linkHref='/blog/angi-case-study'
    />
  </Layout>
)

export default Careers
