import { SPACING, COLORS } from 'helpers/globals'
import { styleUpToSm, styleUpToLg } from 'helpers/screens'

export default {
  container: {
    '& a': {
      color: COLORS.red
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  contentHeader: {
    padding: SPACING.xxxl,
    ...styleUpToSm({
      lineHeight: SPACING.large,
      padding: SPACING.large
    })
  },
  centered: {
    textAlign: 'center'
  },
  content: {
    paddingBottom: SPACING.xl
  },
  photo: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: COLORS.white,
    position: 'relative',
    '&:before': {
      display: 'block',
      content: '""',
      width: '100%',
      paddingTop: '100%;',
      ...styleUpToLg({
        paddingTop: '50%;',
      })
    }
  }
}
