import React from 'react'
import injectSheet from 'react-jss'
import style from './styles'

import hiringPic from 'assets/images/hiring.jpg'

const CareersOverview = ({ classes }) => (
  <div className={classes.container}>
    <div className='container' style={{ maxWidth: 1200 }}>
      <div className={`${classes.centered} ${classes.contentHeader}`}>
        <h1>
          Happy People Create Better Software.
        </h1>
        <h1>
          You Can Help
        </h1>
      </div>
      <div className={`row ${classes.content}`}>
        <div className='col-lg-8'>
          <p>
            <span className={classes.bold}>
              Hint is on a mission to help software teams reach their full potential.
            </span>
          </p>
          <p>
            Our clients deserve an experience that is reliable, understandable, and
            delivers results while maximizing investment. Developers deserve security,
            enrichment, and fulfillment—personally as well as professionally. Every day
            we're building towards a development process where everyone wins and we’re
            looking for like-minded developers and project leaders to join in our mission.
          </p>
          <p>
            People matter. That's the core of what we believe and who we are as a team.
            We’re focused on kindness and integrity to our colleagues, our customers, and
            our community. We work together as a team and learn with humility and
            enthusiasm to develop our craft as software developers. Together we accomplish
            things that would be impossible alone. We invest in each other through pairing
            & mentorship both on and off the clock. Our accomplishments are recognized by
            each other, and when we make mistakes, we learn together.
          </p>
        </div>
        <div className='col-lg-4'>
          <div
            className={classes.photo}
            style={{
              backgroundImage: `url(${hiringPic})`
            }}
          />
        </div>
      </div>
      <div className={`row ${classes.content}`}>
        <div className='col'>
          <p>
            <span className={classes.bold}>
              🌱 Let's grow together.
            </span>
          </p>
          <p>We're a small team now, with a bright future—one which you'll help define. Diversity isn't just about making better software; it's about making each other better. We're building a culture that welcomes different perspectives, experiences and identities. If you look at our team and don't see yourself, give us a chance! We'll grow Hint together.</p>
        </div>
      </div>
      <div className={`row justify-content-center ${classes.content}`}>
        <div className='centered'>
          <h3 id='currentOpenings' className={classes.bold}>Interested? Email us at <a href='mailto:join@hint.io'>join@hint.io</a></h3>
        </div>
      </div>
    </div>
  </div>
)

export default injectSheet(style)(CareersOverview)
